<template>
  <div class="child-content" v-if="children">
    <div
      class="child-content__team"
      v-for="(team, index) in children"
      :key="index"
    >
      <div class="child-content__header">
        <div
          class="child-content__logo"
          v-if="team.avatar"
          :style="{
            'background-image': 'url(' + team.avatar + ')'
          }"
        ></div>
        <ReplacementLogo
          :name="team.name"
          class="child-content__logo--unset"
          v-else
        />
        <div class="child-content__name">
          {{ team.name }}
        </div>
      </div>

      <div class="child-content__settings">
        <div class="child-content__setting">
          <div class="child-content__setting-left">
            <div class="child-content__setting-icon-wrapper">
              <inline-svg
                class="child-content__setting-icon"
                :src="require('../assets/icons/Templates.svg')"
                aria-label="icon"
              ></inline-svg>
            </div>
            <div class="child-content__setting-text">
              {{ $t("components.childContent.settings.createTemplates") }}
            </div>
          </div>
          <Toggle
            v-model="team.createTemplates"
            @click.native="onUpdateChildTeam"
          />
        </div>
        <div class="child-content__setting">
          <div class="child-content__setting-left">
            <div class="child-content__setting-icon-wrapper">
              <inline-svg
                class="child-content__setting-icon"
                :src="require('../assets/icons/Brand Manager.svg')"
                aria-label="icon"
              ></inline-svg>
            </div>
            <div class="child-content__setting-text">
              {{ $t("components.childContent.settings.brandManager") }}
            </div>
          </div>
          <Toggle
            v-model="team.brandManager"
            @click.native="onUpdateChildTeam"
          />
        </div>
        <!-- <div class="child-content__setting">
          <div class="child-content__setting-left">
            <div class="child-content__setting-icon-wrapper">
              <inline-svg
                class="child-content__setting-icon"
                :src="require('../assets/icons/ParentTemplates.svg')"
                aria-label="icon"
              ></inline-svg>
            </div>
            <div class="child-content__setting-text">
              {{ $t("components.childContent.settings.parentTemplates") }}
            </div>
          </div>
          <Toggle
            v-model="team.parentTemplates"
            @click.native="onUpdateChildTeam"
          />
        </div> -->
      </div>
    </div>
  </div>
</template>

<script>
import Toggle from "@/components/Toggle";
import ReplacementLogo from "@/components/ReplacementLogo";
import { mapActions } from "vuex";

export default {
  components: {
    Toggle,
    ReplacementLogo
  },
  props: {
    children: {
      type: Array,
      default: () => []
    },
    team: {
      type: Object,
      default: () => {}
    }
  },
  methods: {
    ...mapActions("teams", ["updateTeam"]),
    onUpdateChildTeam() {
      this.updateTeam(this.team);
    }
  }
};
</script>

<style lang="scss" scoped>
.child-content {
  &__team {
    @apply mb-4 border border-ls-gray-300 rounded;
  }

  &__header {
    @apply bg-ls-gray-100 p-4 flex items-center;
  }

  &__logo {
    @apply bg-cover w-6 h-6 mr-2 rounded-lg;

    &--unset {
      @apply w-6 h-6 mr-2 rounded-lg;
    }
  }

  &__settings {
    width: 100%;
  }

  &__setting {
    @apply flex justify-between px-4 py-2 items-center;

    &:not(:last-of-type) {
      @apply border-b border-ls-gray-200;
    }

    &-left {
      @apply flex items-center;
    }

    &-icon {
      &-wrapper {
        @apply p-3 rounded-full bg-ls-gray-300 mr-2;
      }
    }
  }
}
</style>
