<template>
  <div class="linked-teams">
    <div class="linked-teams__header">
      <div class="linked-teams__title">
        {{ $t("pages.settings.linkedTeams.title") }}
      </div>
      <!-- <Button
        theme="secondary"
        class="linked-teams__create-button"
        @click="showCreateTeamModal = true"
      >
        <inline-svg
          class="linked-teams__create-icon"
          :src="require(`../../assets/icons/Plus.svg`)"
          aria-label="Image"
        ></inline-svg>
        {{ $t("pages.settings.linkedTeams.child.createButton") }}
      </Button> -->
    </div>
    <Card class="linked-teams__card" v-if="showRootTeam">
      <div class="linked-teams__card-title">
        {{ $t("pages.settings.linkedTeams.root.title") }}
      </div>
      <p class="linked-teams__card-text">
        {{ $t("pages.settings.linkedTeams.root.text") }}
      </p>
      <RootContent
        class="linked-teams__screen-activation"
        :team="team"
      ></RootContent>
    </Card>

    <Card class="linked-teams__card" v-if="hasParent">
      <div class="linked-teams__card-title">
        {{ $t("pages.settings.linkedTeams.parent.title") }}
      </div>
      <p class="linked-teams__card-text">
        {{ $t("pages.settings.linkedTeams.parent.text") }}
      </p>
      <ParentContent
        class="linked-teams__screen-activation"
        :team="team"
      ></ParentContent>
    </Card>

    <Card class="linked-teams__card">
      <div class="linked-teams__card-title">
        {{ $t("pages.settings.linkedTeams.child.title") }}
      </div>
      <p class="linked-teams__card-text">
        {{ $t("pages.settings.linkedTeams.child.text") }}
      </p>
      <div class="linked-teams__search">
        <inline-svg
          class="linked-teams__search-icon"
          :src="require('../../assets/icons/Search.svg')"
          aria-label="icon"
        ></inline-svg>
        <input
          class="linked-teams__search-field"
          type="text"
          v-model="searchTerm"
          :placeholder="$t('pages.editor.branches.placeholder')"
        />
      </div>
      <ChildContent
        class="linked-teams__child-content"
        :children="searchList"
        :team="team"
        v-if="children"
      ></ChildContent>
    </Card>
    <!-- <CreateTeam
      v-if="showCreateTeamModal"
      @close="showCreateTeamModal = false"
    ></CreateTeam> -->
  </div>
</template>

<script>
import Card from "@/components/Card";
// import Button from "@/components/Button";
// import CreateTeam from "@/components/CreateTeam";
import ParentContent from "@/components/ParentContent";
import RootContent from "@/components/RootContent";
import ChildContent from "@/components/ChildContent";
import { mapGetters } from "vuex";

export default {
  components: {
    Card,
    // Button,
    // CreateTeam,
    ParentContent,
    RootContent,
    ChildContent
  },
  data: () => ({
    searchTerm: ""
  }),
  computed: {
    ...mapGetters("teams", {
      team: "getCurrentTeam",
      role: "getRole"
    }),
    showRootTeam() {
      return this.team.rootTeam && this.team.rootTeam !== this.team.parentTeam;
    },
    hasParent() {
      return this.team.parentTeam ? true : false;
    },
    children() {
      return this.team.children;
    },
    searchList() {
      if (this.searchTerm) {
        return Object.values(this.children).filter(team => {
          return team.name
            .toLowerCase()
            .includes(this.searchTerm.toLowerCase());
        });
      }

      return Object.values(this.children);
    }
  },
  mounted() {
    if (this.role && this.role.toLowerCase() !== "admin") {
      this.$router.push({
        name: "Dashboard"
      });
    }
  }
};
</script>

<style lang="scss">
.linked-teams {
  &__header {
    @apply flex justify-between mb-4;
  }

  &__title {
    font-weight: 600;
    font-size: 28px;
    line-height: 32px;
  }

  &__card {
    @apply mb-4;
  }

  &__card-title {
    font-weight: 600;
    font-size: 16px;
    line-height: 20px;
    margin-bottom: 0.5rem;
  }

  &__card-text {
    font-weight: normal;
    font-size: 12px;
    line-height: 14px;
  }

  &__search {
    @apply w-full relative mt-4;

    &-icon {
      position: absolute;
      top: 11px;
      left: 10px;
    }

    &-field {
      @apply bg-ls-gray-100 rounded-lg pl-8 pr-3 py-2 w-full;
      font-size: 14px;
      line-height: 20px;
    }
  }

  &__screen-activation,
  &__child-content {
    margin-top: 1.5rem;
  }

  &__create-icon {
    margin-right: 0.5rem;
  }
}
</style>
